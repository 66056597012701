<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-07-02 10:52:10
-->
<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-07-02 10:27:43
-->
<template>
    <div class="box">
        <div class="shareClass1" id="myImage">
            <img class="shareImg1" :src="shareImg" alt="">
        </div>
        <img class="shareImg2" @click="saveImg" src="@/assets/images/btnShare.png" alt="">
    </div>
</template>

<script>
import html2canvas from 'html2canvas'

import * as api from '@/api/goods.js'
export default {
    data() {
        return {
            shareImg: ''
        }
    },
    mounted() {
        this.getShare()
    },
    methods: {
        getShare() {
            api.shareCode().then((res) => {
                this.shareImg = 'data:image/png;base64,' + res.data.dataStr
            })
        },
        saveImg() {
            const options = {
                backgroundColor: null,
            }
            html2canvas(document.querySelector('#myImage'), options).then(
                (canvas) => {
                    let url = canvas.toDataURL('image/png')
                    let a = document.createElement('a')
                    a.href = url
                    a.setAttribute('download', 'sharePost')
                    a.click()
                }
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.box{
  height: 800px;
  position: relative;
}
.shareClass1 {
    border-radius: 10px 10px 10px 10px;
    background-image: url('../assets/images/sm2.jpeg');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height:100%; // text-align: center;
    
    margin: 0 auto;
}

.shareImg1 {
    width: 280px;
    height: 280px;
    position: absolute;
    bottom: 30%;
    left: 23%;
    right:0%;
    margin: auto;
}

.shareImg2 {
    width: 280px;
    height: 80px;
    position: absolute;
    bottom: 15%;
    left: 23%;
    right:0%;
    margin: auto;
    animation: wiggle 1s ease-in-out infinite;
}
@keyframes wiggle {
  0% {
      transform: scale(1.1);
    }
    25% {
      transform: scale(1.2);
    }
    50% {
      transform: scale(1.3);
    }
    75% {
      transform: scale(1.2);
    }
    100% {
      transform: scale(1.1);
    }
}
</style>